'use client'

import Image from "next/image";
import Link from "next/link";

interface ad {
  img: string
  link: string
  alt: string
  width: number
  height: number
}

const ads: { [key: string]: ad } = {
  'wide_skyscraper': {
    img: 'https://static.lespetitespatounes.com/ads/banniere_wide_skyscraper.jpg?format=webp&quality=80&width=160&height=600',
    link: 'https://lespetitespatounes.com/register?utm_source=website&utm_medium=banner&utm_campaign=register_now',
    alt: 'Rejoignez-nous!',
    width: 160,
    height: 600,
  },
  'pave': {
    img: 'https://static.lespetitespatounes.com/ads/banniere_pave_v2.gif?format=webp&quality=80&width=432&height=432',
    link: 'https://lespetitespatounes.com/register?utm_source=website&utm_medium=banner&utm_campaign=register_now',
    alt: 'Rejoignez-nous!',
    width: 432,
    height: 432,
  },
  'leaderboard': {
    img: 'https://static.lespetitespatounes.com/ads/ban_leaderboard_lodging_v2.png?format=webp&quality=100&width=730&height=90',
    link: 'https://lespetitespatounes.com/register?utm_source=website&utm_medium=banner&utm_campaign=new_lodging',
    alt: 'Rejoignez-nous!',
    width: 728,
    height: 90,
  }
}

export function Ad({format}: { format: 'wide_skyscraper' | 'pave' | 'leaderboard' | string }) {
  const ad = ads[format]
  return <Link href={ad.link} className="flex flex-col flex-wrap justify-center"
               style={{width: ad.width + 'px', height: ad.height + 'px'}}>
    <Image src={ad.img} alt={ad.alt} width={ad.width}
           height={ad.height} className="block"/>
  </Link>
}