import classNames from "classnames";
import {Skeleton} from "primereact/skeleton";
import {PropsWithChildren} from "react";

interface SectionProps extends PropsWithChildren<any> {
  hidden?: boolean
  loading?: boolean
}

export function Section(props: SectionProps): JSX.Element {
  return props.hidden ? <></> : props.loading ?
    <section {...props} className={classNames("bg-white rounded-lg p-2 mb-4", props.className)}>
      <Skeleton/>
      <Skeleton/>
    </section>
    :
    <section {...props} className={classNames("bg-white rounded-lg p-2 mb-4", props.className)}/>
}
