'use client'

import {ErrorMessage, SuccessMessage, Title, TitleLabel} from "@/components/atoms/typographies";
import {InputText} from "primereact/inputtext";
import {SButton} from "@/components/atoms/button";
import Link from "next/link";
import * as React from "react";
import {useState} from "react";
import styled from "styled-components";
import {useSubscribeNewsletterMutation} from "@/lib/client/slices/api";
import {Section} from "@/components/atoms/sections";
import {SDivider} from "@/components/atoms/divider";
import Image from "next/image";

const Styled = styled.div`
    .land-text {
        padding: ${props => props.theme.padding}px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .land-image {
        background: url("/images/laptop-gfab8a5078_1280.jpg") center / cover;
        height: 250px;
        border-radius: 6px;
    }

`
export const NewsletterSection = ({lang}: { lang: string }) => {

  const [subscribe, status] = useSubscribeNewsletterMutation()
  const [email, setEmail] = useState('')

  const handleSubscribe = (e: any) => {
    e.stopPropagation()
    if (email) {
      subscribe({email})
    }
  }

  return <Section>
    <Title>Inscrivez-vous à la newsletter</Title>
    <TitleLabel>Recevez le meilleur de nos guides et lieux, ainsi que les actualités à ne pas manquer!</TitleLabel>
    <SDivider/>


    <div className="flex flex-col rounded-lg md:flex-row items-center">
      <div className="basis-1/2 h-96 w-full object-cover md:h-auto md:min-h-64 relative ">
        <Image src={"/images/laptop-gfab8a5078_1280.jpg"} alt={"newsletter_image"} fill/>
      </div>
      <div className="basis-1/2 flex flex-col justify-start p-6">
        <div className="mb-4 text-base text-neutral-600 ">
          {status.isSuccess && <SuccessMessage>Merci pour votre inscription</SuccessMessage>}
          {status.isError && <ErrorMessage>Une erreur s'est produite, merci de réessayer ultérieurement.</ErrorMessage>}
          {!status.isSuccess && <div className="flex w-full">
              <InputText placeholder={"Mon adresse email"} onChange={e => setEmail(e.target.value)} className={'m-2'}
                         disabled={status.isLoading || status.isSuccess}/>
              <SButton label={"S'inscrire"} className={'m-2'} onClick={handleSubscribe}
                       loading={status.isLoading}
                       disabled={status.isLoading || status.isSuccess}/>
          </div>}
        </div>
        <p className="text-xs text-neutral-500 dark:text-neutral-300">
          Les Petites Patounes s'engage à respecter vos données personnelles. <br/>Vous
          pourrez vous désabonner à tout moment. <Link href={"/pages/conditions-generales-utilisation"}>En savoir
          plus</Link>
        </p>
      </div>
    </div>

  </Section>

}
