import(/* webpackMode: "eager", webpackExports: ["NewsletterSection"] */ "/app/app/[lang]/(public)/(home)/_components/NewsletterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Ad"] */ "/app/app/components/atoms/ad.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/components/atoms/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentLayout","AsideLayout"] */ "/app/app/components/molecules/layouts.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Skeleton"] */ "/app/node_modules/primereact/skeleton/skeleton.esm.js");
